<template>
  <invoice-data
    :invoice-id="invoiceId"
    :url="`invoices/${invoiceId}`"
  ></invoice-data>
</template>

<script>
import InvoiceData from "@/components/Invoices/InvoiceData";

export default {
  components: { InvoiceData },
  computed: {
    invoiceId() {
      return this.$route.params.invoice;
    },
  },
};
</script>
